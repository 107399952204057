.Box:hover {
  transform: scale(1.02) translateY(-10px);

}

.Box {
  transition: 0.2s ease-in-out
}

body {
  background-color: rgba(0, 0, 0, 0.465) !important;
  color: white !important;

}